<template>
	<div
		class="pa-10-20"
	>
		<div class="bg-heart-pink radius-20 ">
			<div class=" bg-heart text-right pa-20">
				<div class="size-px-13">{{ $language.point.title_my_point }} <!-- 보유 중인 불렛--></div>
				<div class="mt-10 color-red size-px-20 font-weight-bold">{{ total_point | makeComma }}{{ $language.point.txt_heart }} <!--불렛--></div>
			</div>
			<hr class=" prl-20 under-line">
			<div class="pa-20">
				<div class="justify-space-between items-center">
					<div class="size-px-13">{{ $language.point.title_my_point_available }} <!-- 사용가능한 불렛--></div>
					<div class="color-red size-px-16 font-weight-bold ">{{ item.holding_point | makeComma }}{{ $language.point.txt_heart }} <!--불렛--></div>
				</div>
				<div class="mt-10 justify-space-between items-center">
					<div class="size-px-13">{{ $language.point.title_my_point_support }} <!-- 후원받은 불렛--></div>
					<div class=" color-red size-px-16 font-weight-bold">{{ item.holding_spnsh_point | makeComma }}{{ $language.point.txt_heart }} <!--불렛--></div>
				</div>
			</div>
		</div>

		<div
			class="mt-20 justify-space-between under-line pb-20"
		>
			<button
				class="flex-1 btn_l mr-10"
				:class="is_charge ? 'btn_fill_blue' : 'btn_fill_lightgray' "

				@click="to('PointList')"
			>{{ $language.point.btn_charge }} <!-- 충전 --></button>

			<button
				class="flex-1 btn_l "
				:class="is_list ? 'btn_fill_blue' : 'btn_fill_lightgray' "

				@click="to('PointHistory')"
			>{{ $language.point.btn_list }} <!-- 내역 --></button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PaymentInfo'
		, props: ['user']
		, data: function(){
			return {
				item: {}
			}
		}
		, computed: {
			is_charge: function(){
				let t = false
				if(this.$route.name == 'PointList'){
					t = true
				}

				return t
			}
			, is_list: function(){
				let t = false
				if(this.$route.name == 'PointHistory'){
					t = true
				}

				return t
			}
			, total_point: function(){
				let t = 0
				t += Number(this.item.holding_point) + Number(this.item.holding_spnsh_point)
				return t
			}
		}
		, methods: {
			to: function(name){
				this.$bus.$emit('to', { name: name})
			}

			, getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_user_point
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})
					if(result.success){
						this.item = result.data
						this.$emit('onLoad', this.item)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.getData()
		}
	}
</script>

<style>
</style>