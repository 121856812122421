<template>
	<div class="full-height flex-column">
		<PaymentInfo
			v-if="is_payment_info"
			:user="user"
			:item="item"
			class="mt-50 "
		>
		</PaymentInfo>

		<div
			class="prl-20 full-height"
		>
			<ul
				v-if="items.length > 0"
			>
				<li
					v-for="(point, p_index) in list_purchase"
					:key="'point_' + p_index"
					class="justify-space-between under-line-not-last ptb-10 items-center bg-heart-only"
					@click="setPoint(point)"
				>
					<div class="flex-1 ml-40 ">
						<div class="size-px-16 font-weight-bold color-red">{{ point.charg_point | makeComma }} {{ $language.point.txt_heart }} <!--불렛--></div>
						<div class="size-px-14 font-weight-500 pl-20 bg-won">{{ point.charg_payment_amount | makeComma}}원</div>
					</div>
					<div>
						<button
							class="btn_s btn_outline_blue"
						>{{ $language.point.txt_purchase }} <!-- 구매하기 --></button>
					</div>
				</li>
			</ul>
			<div
				v-else
				class="ptb-50 size-px-14 text-center"
			>{{ $language.point.error_not_point_purchase }}<!-- 구매가능한 포인트 정보가 없습니다.--></div>
		</div>

		<Popup_confirm
			v-if="is_on_payment"
			@click="onPin"
			@cancel="offPayment"
		>

			<template v-slot:title>{{ $language.point.title_heart_payment}} <!-- 불렛 구매 --></template>
			<template
				v-slot:main-txt
			><img :src="require('@/assets/image/icon_heart.svg')" style="width: 20px" />{{ item_payment.charg_point | makeComma }}{{ $language.point.txt_heart }}({{ item_payment.charg_payment_amount | makeComma}} {{ $language.point.txt_heart_payment_ask }}<!-- 원)를 구매하시겠습니까?--></template>

		</Popup_confirm>

		<MyCredit
			v-if="is_on_credit"
			:user="user"

			@click="setCredit"
			@cancel="is_on_credit = false"
		></MyCredit>

		<div
			v-if="is_on_result"
			class="layer-popup"
		>
			<div
				class="full-height flex-column justify-space-between size-px-14"
			>
				<div
					class="full-height flex-column justify-center items-center pa-20"
				>
					<div class="character2 pt-130 width-100 text-center size-px-16 font-weight-600">불렛 충전 완료</div>
					<div class="mt-20 bg-heart-pink pa-10 width-100 radius-20 text-center color-red size-px-16 font-weight-600"><img :src="require('@/assets/image/icon_heart.svg')" style="width: 30px;"/> {{ item_result.charg_point | makeComma }}불렛</div>
				</div>
				<div
					class="mt-auto pa-10"
				>
					<div v-if="false" class="pa-10 justify-space-between">
						<span>결제 수단</span>
						<span>{{ item_credit.payment_mthd_number }}</span>
					</div>
					<div class=" pa-10 justify-space-between">
						<span>결제 금액</span>
						<span class="color-red font-weight-600">{{ item_result.charg_payment_amount | makeComma }}원</span>
					</div>
					<div
						class="mt-10 pa-10 btn_area"
					>
						<button
							@click="offResult"
							class="btn_m btn_fill_blue"
						>확인</button>
					</div>
				</div>
			</div>
		</div>

		<div
			v-if="is_on_purchase_cancel"
			class="position-fixed"
			style="width: 100%; height: 120px; padding: 20px; top: unset; bottom: 0px !important; left: 0; z-index: 99999; text-align: center"
		><button class="btn_l btn_outline_gray" @click="postPaymentCancel">CANCEL</button></div>
	</div>

</template>

<script>

import PaymentInfo from "@/view/Payment/PointInfo";
import Popup_confirm from "@/view/Layout/PopupConfirm";
import MyCredit from "@/view/Mypage/MyCredit";
export default {
	name: 'PaymentList'
	, components: {MyCredit, Popup_confirm, PaymentInfo}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'payment list'
				, title: 'MY 불렛'
				, not_footer: true
				, type: 'mypage'
				// , from: 'myPage'
				, is_save: true
				, callBack: {
					name: 'to_payment_history'
					, title: '결제내역'
				}
			}
			, item: {
				point: 0
			}
			, items: [
			]
			, item_payment: {}
			, item_credit: {}
			, is_on_payment: false
			, is_on_credit: false
			, items_credit: []
			, item_search: {
				page: 1
				, list_cnt: 10
			}
			, item_point: {

			}
			, item_result: {

			}
			, is_on_result: false
			, is_payment_info: true
			, items_ios_list: []
			, is_on_purchase_cancel: false
		}
	}
	, computed: {
		list_purchase: function(){
			let t = []

			if(this.items_ios_list.length > 0){
				t = this.items
			}else{
				t = this.items
			}

			return t
		}
	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_user_point_list
					, data: {
						member_number: this.user.member_number
						, mobile_opsys_code: this.$common.getOsCode()
						, payment_type_code: this.$common.getPaymentCode()
					}
					, type: true
				})

				if(result.success){
					this.item_point = result.data
					this.items = result.data.point_charg_info_list

console.log('items', this.items)
					await this.inAppPurchase();
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postPayment: async function(pinnumber){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_user_point
					, data: {
						member_number: this.user.member_number
						, point_number: this.item_point.point_number
						, point_charg_number: this.item_payment.point_charg_number
						, pinnumber: pinnumber
						, payment_mthd_number: this.item_credit.payment_mthd_number
						, charg_point: Number(this.item_payment.charg_point)
						, charg_payment_amount: Number(this.item_payment.charg_payment_amount)
					}
					, type: true
				})

				if(result.success){
					this.item_result = result.data
					this.is_on_result = true
					this.is_payment_info = false
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPayment: function(item){
			this.is_on_payment = true
			this.item_payment = item
		}
		, offPayment: function(){
			this.is_on_payment = false
			this.item_payment = {}
		}
		, onPin: function(){
			this.$bus.$off('pinCallback')
			this.$bus.$emit('onPin', 'check')
			this.$bus.$on('pinCallback', (type, pin) => {
				this.postPayment(pin)
			})
		}
		, onCredit: function(){
			this.is_on_credit = true
		}
		, setCredit: function(credit){
			this.item_credit = credit
			this.is_on_credit = false
			this.is_on_payment = true
		}
		, getCreditList: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_credit_list
					, data: {
						member_number: this.user.member_number
						, page_number: this.item_search.page
						, pagerecnum: this.item_search.list_cnt
					}
					, type: true
				})
				if(result.success){
					this.items_credit = result.data.payment_mthd_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toCredit: function(){
			this.$bus.$emit('to', { name: 'mafiaCreditRegist'})
		}
		, setPoint: function(item){
			this.item_payment = item
			if(this.$common.getMobile() == 'ios'){
				try{
					this.$bus.$emit('on', true)
					this.$common.inAppWebviewCommunity('android', 'inAppIosPurchase:' + item.point_charg_number)
				}catch (e){
					this.$bus.$emit('on', false)
					this.onCredit()
				}
			}else{
				this.onCredit()
			}
		}
		, offResult: function(){
			this.is_on_payment = false
			this.is_on_result = false
			this.is_payment_info = true
		}

		, postConfirmIos: async function(data){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_inapp_ios_confirm
					, data: {
						member_number: this.user.member_number
						, point_charg_number: data.productID
						, purchase_id: data.purchaseID
						, charg_point: Number(this.item_payment.charg_point)
						, charg_payment_amount: Number(this.item_payment.charg_payment_amount)
						, purchase_token: data.serverVerificationData
					}
					, type: true
				})
				if(result.success){
					let charg_payment_number = result.data.charg_payment_number

					this.$common.inAppWebviewCommunity('android', 'inappconfirm:' + charg_payment_number + ':' + data.purchaseID)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.offPurchase()
			}
		}
		, postCompleteIos: async function(data){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_inapp_ios_complete
					, data: {
						member_number: this.user.member_number
						, point_charg_number: this.item_payment.point_charg_number
						, charg_payment_number: data.charg_payment_number
					}
					, type: true
				})
				if(result.success){

					this.item_result = result.data
					this.is_on_result = true
					this.is_payment_info = false
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.offPurchase()
			}
		}
		, offPurchase: function(){
			this.$bus.$emit('on', false)
			this.is_on_purchase_cancel = false;
		}
		, postPaymentCancel: function(){

			try {
				this.$common.inAppWebviewCommunity('android', 'inappcancel')
			}catch (e){
				console.log(e)
			}finally {
				this.offPurchase()
			}
		}

		, inAppPurchase: async function(){

			if(this.$common.getMobile() == 'ios'){
				try{
					this.$common.inAppWebviewCommunity('android', 'inAppIos')
					window.purchase = async () => {
						console.log('window.purchase')
						this.$bus.$emit('notify', { type: 'success', message: '결제요청중입니다.'})
						this.is_on_purchase_cancel = true
					}
					window.purchase_pending = async () => {
						console.log('window.purchase_pending')
						this.$bus.$emit('notify', { type: 'success', message: '결제대기처리중입니다.'})
						this.is_on_purchase_cancel = true
					}
					window.not_purchase = async () => {
						console.log('window.not_purchase')
						this.$bus.$emit('notify', { type: 'error', message: '결제가능한 상품이 없습니다.'})
						this.$bus.$emit('on', false)
						this.is_on_purchase_cancel = false
					}
					window.purchase_confirm = async (data) => {
						console.log('window.purchase_confirm')
						console.log(this.item_payment, data)
						if(!this.item_payment.point_charg_number) {
							this.$bus.$emit('notify', { type: 'success', message: '완료되지 않은 결제건을 진행합니다'})
							console.log(' in items ', this.items)
							this.items.filter( async (item) => {
								console.log(item.point_charg_number + ' : ' + data.productID)
								if(item.point_charg_number == data.productID){
									this.item_payment = item
									if (data.result) {
										await this.postConfirmIos(data)
									} else {
										this.$bus.$emit('notify', {type: 'error', message: data.message})
									}
								}
							})
						}else{
							if (data.result) {
								await this.postConfirmIos(data)
							} else {
								this.$bus.$emit('notify', {type: 'error', message: data.message})
							}
						}
					}
					window.purchase_complete = async (data) => {
						console.log('window.purchase_complete')
						console.log(data)
						await this.postCompleteIos(data)
					}
					window.purchase_result = async (data) => {
						console.log('window.purchase_result')
						console.log(data)
					}
					window.purchase_cancel = async () => {
						console.log('window.purchase_cancel')
						this.offPurchase()

					}
				}catch (e){
					console.log(e)
				}
			}

		}
	}
	, async created() {
		this.$emit('onLoad', this.program)

		this.$bus.$off('callBack')
		this.$bus.$on('callBack', (call) => {
			if(call.name == 'to_payment_history'){
				this.$emit('to', { name: 'PaymentHistory'})
			}
		})

		await this.getData()
		await this.getCreditList()
	}
}
</script>

<style>
</style>